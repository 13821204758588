// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-backflow-preventer-testing-jsx": () => import("./../../../src/pages/backflow-preventer-testing.jsx" /* webpackChunkName: "component---src-pages-backflow-preventer-testing-jsx" */),
  "component---src-pages-backflow-prevention-jsx": () => import("./../../../src/pages/backflow-prevention.jsx" /* webpackChunkName: "component---src-pages-backflow-prevention-jsx" */),
  "component---src-pages-bloor-west-village-jsx": () => import("./../../../src/pages/bloor-west-village.jsx" /* webpackChunkName: "component---src-pages-bloor-west-village-jsx" */),
  "component---src-pages-commercial-jsx": () => import("./../../../src/pages/commercial.jsx" /* webpackChunkName: "component---src-pages-commercial-jsx" */),
  "component---src-pages-commercial-plumbing-jsx": () => import("./../../../src/pages/commercial-plumbing.jsx" /* webpackChunkName: "component---src-pages-commercial-plumbing-jsx" */),
  "component---src-pages-commercial-projects-jsx": () => import("./../../../src/pages/commercial-projects.jsx" /* webpackChunkName: "component---src-pages-commercial-projects-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-cross-connection-survey-jsx": () => import("./../../../src/pages/cross-connection-survey.jsx" /* webpackChunkName: "component---src-pages-cross-connection-survey-jsx" */),
  "component---src-pages-gallery-jsx": () => import("./../../../src/pages/gallery.jsx" /* webpackChunkName: "component---src-pages-gallery-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-plumbing-backflow-jsx": () => import("./../../../src/pages/plumbing-backflow.jsx" /* webpackChunkName: "component---src-pages-plumbing-backflow-jsx" */),
  "component---src-pages-plumbing-jsx": () => import("./../../../src/pages/plumbing.jsx" /* webpackChunkName: "component---src-pages-plumbing-jsx" */),
  "component---src-pages-residential-jsx": () => import("./../../../src/pages/residential.jsx" /* webpackChunkName: "component---src-pages-residential-jsx" */),
  "component---src-pages-residential-plumbing-jsx": () => import("./../../../src/pages/residential-plumbing.jsx" /* webpackChunkName: "component---src-pages-residential-plumbing-jsx" */),
  "component---src-pages-sunnylea-1-jsx": () => import("./../../../src/pages/sunnylea-1.jsx" /* webpackChunkName: "component---src-pages-sunnylea-1-jsx" */),
  "component---src-pages-sunnylea-2-jsx": () => import("./../../../src/pages/sunnylea-2.jsx" /* webpackChunkName: "component---src-pages-sunnylea-2-jsx" */),
  "component---src-pages-thanks-jsx": () => import("./../../../src/pages/thanks.jsx" /* webpackChunkName: "component---src-pages-thanks-jsx" */),
  "component---src-pages-the-kingsway-residence-1-jsx": () => import("./../../../src/pages/the-kingsway-residence-1.jsx" /* webpackChunkName: "component---src-pages-the-kingsway-residence-1-jsx" */),
  "component---src-pages-the-kingsway-residence-2-jsx": () => import("./../../../src/pages/the-kingsway-residence-2.jsx" /* webpackChunkName: "component---src-pages-the-kingsway-residence-2-jsx" */)
}

